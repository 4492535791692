<template>

	<van-nav-bar title="门店编辑" left-text="返回" icon="edit" fixed left-arrow @click-left="onClickLeft" />
	<div class="choice" style="margin:50px 0px;">
		<van-form @submit="onSubmit">
			<van-field v-model="getInfo.name" required label="门店名称" placeholder="请输入用户名" />
			<van-field v-model="getInfo.trade_name" required readonly clickable label="所属行业" placeholder="请选择行业"
				@click="showPicker = true" />
			<van-popup v-model:show="showPicker" round position="bottom">
				<van-picker :columns="trade" :columns-field-names="{text:'name', value: 'id'}"
					@cancel="showPicker = false" @confirm="onConfirm" />
			</van-popup>
			<van-field v-model="addresss" is-link readonly label="选择省市区" placeholder="请选择所在地区" @click="show = true" />
			<van-popup v-model:show="show" round position="bottom">
				<van-cascader v-model="cascaderValue" title="请选择所在地区" :options="options"
					:field-names=" {text: 'label', value: 'value', children: 'children' }" @close="show = false"
					@finish="onFinish" />
			</van-popup>
			<van-field v-model="getInfo.address" label="门店地址" placeholder="请输入商户地址" />
			<van-field v-model="getInfo.lng" label="经度" placeholder="请输入经度" />
			<van-field v-model="getInfo.lat" label="纬度" placeholder="请输入纬度" />
			<!-- 商品图片-->
			<van-field name="uploader" required label="门店主图" :rules="[{ required: true, message: '请选择商品主图' }]">
				<template #input>
					<van-uploader :max-count="1" name="image" :before-delete="afterDelete" :after-read="afterRead"
						:max-size="2048 * 1024" @oversize="onOversize" v-model="image" accept="image/*" />
				</template>
			</van-field>
			<!-- 组图 -->
			<van-field name="uploader" label="轮播图">
				<template #input>
					<van-uploader :before-delete="afterDelete" :after-read="afterRead" name="images" multiple
						:max-size="2048 * 1024" @oversize="onOversize" :max-count="4" v-model="images" />
				</template>
			</van-field>

			<div style="margin: 16px;">
				<van-button round block type="primary" native-type="submit">提交</van-button>
			</div>
		</van-form>
	</div>
</template>

<script>
	import {
		storeInfo,
		edit,
		trade,
		options
	} from '@/api/merchant'
	import {
		reactive
	} from 'vue';
	import {
		uploadImage
	} from "@/api/common"
	import {
		Toast
	} from 'vant'
	import
	http
	from '../../utils/config.js'
	export default {
		setup() {

			const onOversize = (file) => {
				console.log(file);
				Toast('文件大小不能超过 2M');
			};
			return {
				onOversize
			};
		},
		data() {
			return {
				imageUrl: http.imageUrl,
				addresss: null,
				trade: [],
				options: [],
				storeId: null,
				show: false,
				showPicker: false,
				getInfo: {
					id: null,
					name: '',
					image: [],
					province: null,
					city: null,
					county: null,
					address: '',
					trade_id: '',
					lng: '',
					lat: '',
					status: 1,
					slideshow: [],
					trade_name: '',
					images: []
				},
				uploads: [],
				image: [],
				images: [],
			}

		},
		mounted() {
			this.storeId = this.$route.query.id
			this.getStoreInfo()

			this.getTrade()
		},

		methods: {
			getTrade() {
				trade().then((res) => {
					this.trade = res.data
					console.log(this.trade)
				})
			},
			afterDelete(file, detail) {
				switch (detail.name) {
					case 'image':
						this.formdata.image = ''
						this.image.splice(detail.index, 1)
						break
					case 'images':
						this.uploads.splice(detail.index, 1)
						this.images.splice(detail.index, 1)
						break
				}
			},
			afterRead(file, detail) {
				const formData = new FormData();
				formData.append('file', file.file)
				uploadImage(formData).then((res) => {
					console.log(res)
					if (detail.name === 'image') {
						this.getInfo.image = res.data.path
						console.log(this.getInfo.image)
					} else {
						this.uploads.push(res.data.path)
					}
				})
			},
			onFinish(value) {
				this.addresss = value.selectedOptions.map((option) => option.label).join('/')
				this.getInfo.county = value.value
				console.log(this.getInfo.county)
				this.show = false
			},
			getStoreInfo() {
				storeInfo({
					"sid": this.storeId
				}).then((res) => {
					console.log(res)
					// this.image.push(this.imageUrl+res.data.image) 
					this.image = [{
						url: this.imageUrl + res.data.image
					}]
					for (const i in res.data.slideshow) {
						this.images.push({
							url: this.imageUrl + res.data.slideshow[i]
						})
					}
					console.log(this.image)
					this.getInfo = res.data
					this.addresss = res.data.province_label + "/" + res.data.city_label + '/' + res.data
						.county_label
					console.log(this.getInfo.city)
					options({
						parent_code: this.getInfo.city
					}).then((res) => {
						this.options = [{
							label: this.getInfo.province_label,
							value: this.getInfo.province,
							children: [{
								label: this.getInfo.city_label,
								value: this.getInfo.city,
								children: res.data.data
							}],
						}]
						console.log(this.options)
					})
				})
			},
			showPopup() {
				this.show = true;
			},
			onSubmit(values) {
				this.getInfo.slideshow = this.uploads.join(';')
				console.log(this.getInfo)
				console.log('submit', values);
				let data = this.getInfo
				edit(data).then((res) => {
					console.log(res)
					if (res.code == 200) {
						Toast.success('修改成功');
						this.$router.push('/store');
					} else {
						Toast.fail('修改失败');
					}
				})
			},
			onClickLeft() {
				this.$router.go(-1);
			},
			onConfirm(value) {
				console.log(value)
				this.getInfo.trade_name = value.name;
				this.getInfo.trade_id = value.id;
				this.showPicker = false;
			},
		},

	}
</script>

<style lang="less" scoped>
	.choice {
		min-height: calc(100vh);

		::v-deep .van-cell__value {
			color: #df2020;
		}

		::v-deep .van-cell-group__title {
			color: #df2020;
		}
	}

	.shuju {
		text-align: center;
		background: #fff;
		font-size: 14px;
		height: 6rem;
		line-height: 3rem;
		color: #df2020;

	}
</style>
