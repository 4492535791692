import request from '@/utils/request'

/**
 * 上传图片
 * @param data
 * @returns {AxiosPromise}
 */
export function uploadImage(data) {
    return request({
        url: '/upload/image/one',
        method: 'post',
        data: data
    })
}
